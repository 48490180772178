<template>
  <a-modal
      :title="title"
      :width="820"
      :visible="visible"
      :confirmLoading="confirmLoading"
      :maskClosable="false"
      :destroyOnClose="true"
      @ok="handleSubmit"
      @cancel="handleCancel"
  >
    <a-spin :spinning="confirmLoading">
      <a-form :form="form">
        <a-form-item label="标题" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-input
              placeholder="请输入标题"
              v-decorator="['content_name', {rules: [{required: true, message: '请输入标题'}]}]"
          />
        </a-form-item>
        <a-form-item label="检查项目" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <SelectInspectProjectTree
              placeholder="请选择检查项目"
              v-decorator="['icp_ids', {rules: [{required: true, message: '请选择检查项目'}]}]"
          />
        </a-form-item>
        <a-form-item label="状态" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-radio-group
              v-decorator="['status', { initialValue: 0,rules: [{ required: true }] }]"
          >
            <a-radio :value="0">启用</a-radio>
            <a-radio :value="1">停用</a-radio>
          </a-radio-group>
        </a-form-item>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
import _ from "lodash";
import * as Api from '@/api/inspect/inspectContent'
import {SelectInspectProjectTree} from "@/components";

export default {
  components: {SelectInspectProjectTree},
  data() {
    return {
      // 对话框标题
      title: '',
      // 标签布局属性
      labelCol: {
        span: 4
      },
      // 输入框布局属性
      wrapperCol: {
        span: 13
      },
      // modal(对话框)是否可见
      visible: false,
      // modal(对话框)确定按钮 loading
      confirmLoading: false,
      // 当前表单元素
      form: this.$form.createForm(this),
      // 当前记录
      record: {},
      // 当前模式 add新增 edit编辑
      mode: "",

      project_list: [{}]
    }
  },
  methods: {
    handleAdd() {
      this.project_list.push({})
    },
    handleDel(index) {
      if (this.project_list.length <= 1) {
        this.$message.error('至少保留一项')
        return
      }
      this.project_list.splice(index, 1)
    },

    /**
     * 新增对话框
     */
    add() {
      this.mode = "add"
      this.title = '新增检查内容'

      this.visible = true
    },

    /**
     * 编辑对话框
     */
    edit(record) {
      this.mode = "edit"
      this.title = '编辑检查内容'
      this.visible = true
      this.record = record
      this.setFieldsValue()
    },

    /**
     * 设置默认值
     */
    setFieldsValue() {
      const {form: {setFieldsValue}} = this
      this.$nextTick(() => {
        this.record.project = this.record.project_array
        const data = _.pick(this.record, ['content_name','icp_ids', 'status'])
        setFieldsValue(data)

        var list = []
        this.project_list = this.record.project_list
        this.record.project_list.forEach((item, index) => {
          list['project.' + index + '.project_name'] = item.project_name
        })
        this.$nextTick(() => {
          const data2 = _.pick(list, Object.keys(list))
          setFieldsValue(data2)
        })
      })
    },

    /**
     * 确认按钮
     */
    handleSubmit(e) {
      e.preventDefault()
      const {form: {validateFields}} = this
      // 表单验证
      validateFields((errors, values) => {
        // 提交到后端api
        !errors && this.onFormSubmit(values)
      })
    },

    /**
     * 关闭对话框事件
     */
    handleCancel() {
      this.visible = false
      this.form.resetFields()
    },

    /**
     * 提交到后端api
     */
    onFormSubmit(values) {
      this.confirmLoading = true
      var sendApi = null
      if (this.mode == "add") {
        sendApi = Api.add({form: values})
      } else {
        sendApi = Api.edit({ico_id: this.record['ico_id'], form: values})
      }
      sendApi.then((result) => {
        // 显示成功
        this.$message.success(result.message, 1.5)
        // 关闭对话框
        this.handleCancel()
        // 通知父端组件提交完成了
        this.$emit('handleSubmit', values)
      })
          .finally((result) => {
            this.confirmLoading = false
          })
    }

  }
}
</script>
<style scoped lang="less">
.table {
  border: 1px #e8e8e8 solid;

  .tr {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px #d9d9d9 solid;
    //height: 40px;
    //line-height: 40px;

    &:last-child {
      border-bottom: none;
    }

    .td {
      padding: 0 10px;
      //height: 40px;
      width: 40%;
      //text-align: center;
      border-right: 1px #d9d9d9 solid;

      input {
        outline: none;
        text-align: center;
      }

      &:last-child {
        border-right: none;
      }

      .ant-form-item {
        margin-bottom: 0;
      }
    }
  }
}
</style>
