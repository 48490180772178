<template>
  <a-row :gutter="10">
    <a-col :span="12">
      <inspect-content/>
    </a-col>
    <a-col :span="12">
      <inspect-project/>
    </a-col>
  </a-row>
</template>
<script>
import {STable} from "@/components";
import inspectContent from "@/views/secure/inspectContent/Index.vue";
import inspectProject from "@/views/secure/inspectProject/Index.vue";

export default {
  name: 'Index',
  components: {
    inspectContent,
    inspectProject,
  }
}
</script>
<style scoped lang="less">

</style>
